import React from 'react';
import '../Css/DevOps.css';
import certificateImg from '../assets/certificate.webp';
import Contact from '../pages/Contact'
import Roadmap from '../Components/Roadmap';
import WhatsDevOps from '../Components/WhatsDevOps'
import PageForm from '../Components/PageForm';
import Syllabus from '../Components/Syllabus';
import KeyFeatures from '../Components/KeyFeatures';
import Faq from '../Components/Faq'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MarqueeComponent from '../Components/MarqueeComponent'
import dev1 from '../assets/Projects/DA/1.png';
import dev2 from '../assets/Projects/DA/2.png';
import dev3 from '../assets/Projects/DA/3.png';
import dev4 from '../assets/Projects/DA/4.png';
import dev5 from '../assets/Projects/DA/5.png';
import dev6 from '../assets/Projects/DA/6.png';
import rev1 from '../assets/Reviews/Da/1.png';
import rev2 from '../assets/Reviews/Da/2.png';
import rev3 from '../assets/Reviews/Da/3.png';
import rev4 from '../assets/Reviews/Da/4.png';
import rev5 from '../assets/Reviews/Da/5.png';
import rev6 from '../assets/Reviews/Da/6.png';
import rev7 from '../assets/Reviews/Da/7.png';
import rev8 from '../assets/Reviews/Da/8.png';
import RegularSlider from '../Components/RegularSlider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle, faStar } from '@fortawesome/free-solid-svg-icons';
import JobPreparation from '../Components/JobPreparation';
import Marq from '../Components/CustomMarquee';
import BatchesPage from "../Components/Batches";
import CourseOpportunities from '../Components/CourseOpportunities'


const DataEngineering = () => {


    const roadmapData = [
        {
            title: 'Introduction to Data Engineering',
            points: [
                'What is Data Engineering?',
                'Use Cases and Applications',
                'Data Engineer vs Data Scientist',
                'Data Engineering Problems and Solutions',
                'Overview of Data Engineering Tools'
            ]
        },
        {
            title: 'Python for Data Engineering',
            points: [
                'Python Basics: History, Installation, First Program',
                'Data Types, Variables, and Keywords',
                'Control Flow Statements (if-else, loops, break, continue)',
                'Functions, Lambda Expressions, Regular Expressions',
                'Object-Oriented Programming in Python',
                'Exception Handling and GUI with Tkinter',
                'SQL Connection with Python'
            ]
        },
        {
            title: 'SQL and Databases',
            points: [
                'Introduction to SQL and Databases',
                'Types of DBMS: Relational and NoSQL',
                'Basic SQL Queries (SELECT, WHERE, GROUP BY, ORDER BY)',
                'Joins (Inner, Outer, Left, Right, Self)',
                'Subqueries and Nested Queries',
                'Stored Procedures and Triggers',
                'PL/SQL: Cursors, Exception Handling, Stored Functions, Packages'
            ]
        },
        {
            title: 'Data Warehousing Concepts',
            points: [
                'What is a Data Warehouse?',
                'Difference Between Data Warehouse and Data Lake',
                'Data Warehousing Tools: Snowflake, Redshift, BigQuery',
                'Data Modeling and Schema Design',
                'ETL vs ELT Pipelines',
                'Working with Different Databases in Cloud'
            ]
        },
        {
            title: 'Big Data and Distributed Systems',
            points: [
                'Introduction to Big Data and Hadoop',
                'HDFS and Hadoop Ecosystem',
                'Batch vs Streaming Data Processing',
                'Apache Spark Overview and Installation',
                'RDDs, DataFrames, and Datasets in Spark',
                'Spark SQL and Data Processing',
                'Real-time Processing with Spark Streaming'
            ]
        },
        {
            title: 'Cloud Computing and Data Engineering',
            points: [
                'Introduction to Cloud Computing',
                'AWS, GCP, and Azure for Data Engineering',
                'Data Storage on Cloud: S3, BigQuery, Azure Data Lake',
                'Data Pipelines with AWS Glue and Dataflow',
                'Cloud-based ETL and Data Processing'
            ]
        },
        {
            title: 'Data Ingestion and Processing',
            points: [
                'Data Ingestion Tools: Kafka, Kinesis, Flume',
                'Batch vs Real-time Data Processing',
                'Workflow Orchestration with Apache Airflow',
                'ETL Pipelines using dbt and Apache NiFi',
                'Monitoring and Debugging Pipelines'
            ]
        },
        {
            title: 'NoSQL Databases and MongoDB',
            points: [
                'Introduction to NoSQL Databases',
                'MongoDB Basics and Installation',
                'CRUD Operations in MongoDB',
                'Schema Design and Indexing in MongoDB',
                'Aggregation Framework and Performance Optimization'
            ]
        },
        {
            title: 'Real-time Data Processing',
            points: [
                'Introduction to Real-time Data Processing',
                'Apache Kafka: Producers, Consumers, Topics, Partitions',
                'Building Streaming Pipelines with Kafka and Spark',
                'Streaming ETL with Apache Flink'
            ]
        },
        {
            title: 'Projects and Resume Preparation',
            points: [
                'Capstone Projects in Data Engineering',
                'Building End-to-End Data Pipelines',
                'Optimizing and Scaling Data Workflows',
                'Preparing for Data Engineering Interviews',
                'Resume Preparation and Job Readiness'
            ]
        }
    ];

    const syllabus = [
        {
            title: 'Python Basics',
            subpoints: [
                'What is Python and history of Python?',
                'Installing Anaconda, Jupyter Notebook',
                'First Program',
                'Python Identifiers, Keywords, and Indentation',
                'Comments',
                'Getting User Input',
                'Python Data Types',
                'Keywords and Variables',
                'Python Inbuilt Functions',
            ],
        },
        {
            title: 'Control-Flow Statements',
            subpoints: [
                'If-else, Elif',
                'While loop, For loop, Range function',
                'Break, Continue, Assert, Pass, Return',
            ],
        },
        {
            title: 'Data Structures',
            subpoints: [
                'Lists in Python (Code Walkthrough)',
                'Iterators',
                'Tuples in Python (Code Walkthrough)',
                'Dictionaries in Python (Code Walkthrough)',
                'Sets in Python (Code Walkthrough)',
                'More examples on Data Structures',
            ],
        },
        {
            title: 'Functions',
            subpoints: [
                'Defining and Calling Functions',
                'Inbuilt Functions',
                'User-Defined Functions',
                'Lambda Function, Split, Strip, Map, Filter, Format functions',
                'Regular Expressions Basics',
            ],
        },
        {
            title: 'Object-Oriented Programming (OOP)',
            subpoints: [
                'Introduction to OOP concepts: Class, Object, Self',
                'Constructors, Global vs. Local Variables',
                'Static and Dynamic Variables',
                'Abstraction, Inheritance, Encapsulation, Polymorphism',
                'Code Walkthroughs on OOP concepts',
            ],
        },
        {
            title: 'Exception Handling & GUI',
            subpoints: [
                'Exception Handling in Python: try-except-else, finally clause',
                'Raising Exceptions and User-Defined Exceptions',
                'GUI with Tkinter',
            ],
        },
        {
            title: 'Miscellaneous Topics',
            subpoints: [
                'SQL Connection with Python using SQLITE',
                'Multi-Threading and Multi-Processing',
                'Web-scraping with BeautifulSoup Library',
                'Numpy Library for Data Analysis',
                'Pandas Library for Data Analysis',
                'Matplotlib Library for Data Visualization',
                'Revision Sessions and Assignment Discussions',
            ],
        },
        {
            title: 'Project Discussion',
            subpoints: [
                'Defining Business Problem, Constraints, Flow Diagram',
                'Libraries Used, Results, Conclusion, Future Scope, References',
            ],
        },
        {
            title: 'SQL Basics',
            subpoints: [
                'Introduction to SQL & MySQL',
                'Basic SELECT Statement, Aggregate Operators',
                'JOINS (Inner, Outer, Left, Right, Self Join)',
                'Subqueries, Triggers, Stored Procedures',
                'DML/DDL operations: Insert, Update, Delete, Create Table',
            ],
        },
        {
            title: 'PL/SQL Advanced Topics',
            subpoints: [
                'Stored Procedures with parameters',
                'Functions, Packages, Triggers, and Collections in PL/SQL',
            ],
        },
        {
            title: 'Data Engineering',
            subpoints: [
                'Introduction to Data Engineering & Data Warehouse',
                'Cloud Computing, Use Cases, AWS Tools for Data Engineering',
                'Data Ingestion Tools: Kinesis, Snowball, AWS Glue, etc.',
                'Data Storage and Warehouse Tools: S3, Redshift',
                'MongoDB: CRUD operations, Data Modelling, Aggregation, and Indexing',
            ],
        },
        {
            title: 'Spark & Hadoop',
            subpoints: [
                'Overview of Hadoop',
                'Hadoop Cluster and Racks in detail',
                'Overview of MapReduce',
                'Big Data Concepts and Data Types',
                'Concept of Streaming Data and Different Tools Utilization',
                'HDFS and Basic Hadoop commands',
            ],
        },
    ];

    const keyFeatures = [
        "200+ Hours of comprehensive course duration",
        "100% Job-oriented training with real-world projects",
        "Experienced instructors with expertise in Data Engineering technologies",
        "Free demo class available for hands-on experience",
        "Over 300+ successful batches with industry-leading results",
        "Certification guidance for top industry-recognized certifications",
        "Hands-on experience with Data Ingestion, Processing, and Storage tools",
        "Comprehensive coverage of SQL, NoSQL, Cloud Computing, and Big Data technologies",
        "Training on leading Data Engineering platforms like AWS, Spark, Hadoop, and MongoDB",
        "Industry-specific case studies and real-time problem-solving techniques",
        "Post-training placement support and resume building",
    ];

    const faqs = [
        {
            question: "What is Data Engineering?",
            answer: "Data Engineering is the practice of designing, building, and maintaining systems and infrastructure that allow organizations to collect, store, process, and analyze large amounts of data efficiently.",
        },
        {
            question: "What skills are required to become a Data Engineer?",
            answer: "Key skills include proficiency in programming languages like Python, SQL, and Java, knowledge of big data technologies (e.g., Hadoop, Spark), data warehousing, ETL processes, cloud platforms (e.g., AWS, Azure), and experience with databases and data pipelines.",
        },
        {
            question: "What is the role of SQL in Data Engineering?",
            answer: "SQL is a fundamental skill for Data Engineers. It is used to query databases, manipulate data, perform aggregations, and join datasets, making it essential for building data pipelines and ensuring data integrity.",
        },
        {
            question: "How does Python contribute to Data Engineering?",
            answer: "Python is widely used in Data Engineering for building data pipelines, processing data, and automating tasks. It offers powerful libraries like Pandas, NumPy, and Dask for handling data, as well as tools for working with cloud platforms and big data frameworks.",
        },
        {
            question: "What is the difference between Data Engineering and Data Science?",
            answer: "Data Engineers focus on creating and managing the infrastructure needed to collect, process, and store data, while Data Scientists analyze data to extract actionable insights, build models, and perform advanced analytics.",
        },
        {
            question: "What is the importance of Data Warehousing in Data Engineering?",
            answer: "Data Warehousing allows organizations to consolidate data from various sources into a central repository, making it easier for businesses to analyze large datasets and gain insights. Data Engineers are responsible for designing and maintaining these data storage solutions.",
        },
        {
            question: "What are ETL processes, and why are they important?",
            answer: "ETL (Extract, Transform, Load) processes are critical in Data Engineering for moving data from different sources into a data warehouse. Data Engineers design and manage ETL pipelines to ensure that data is clean, accurate, and properly structured for analysis.",
        },
        {
            question: "How do cloud technologies impact Data Engineering?",
            answer: "Cloud platforms like AWS, Azure, and Google Cloud have revolutionized Data Engineering by providing scalable, cost-effective solutions for data storage, processing, and analytics. Data Engineers leverage cloud services to build and deploy data pipelines in the cloud.",
        },
        {
            question: "What is a Data Pipeline?",
            answer: "A Data Pipeline is a series of automated steps or processes that collect, transform, and store data from various sources. Data Engineers design pipelines to ensure that data flows seamlessly and is available for analysis in real-time or batch mode.",
        },
        {
            question: "What are some common data quality issues Data Engineers face?",
            answer: "Common data quality issues include missing values, duplicates, inconsistent formats, incorrect data types, and outliers. Data Engineers apply data cleaning techniques to address these issues and ensure that the data is accurate and ready for analysis.",
        },
    ];

    const whatsData = [
        {
            title: 'What is Data Engineering?',
            points: [
                'Data Engineering is the process of designing, building, and managing data pipelines that transform raw data into usable information for analytics and decision-making.',
                'It plays a crucial role in handling large-scale data, ensuring it is clean, organized, and accessible for businesses to make data-driven decisions efficiently.',
                'With the rapid growth of big data, the demand for Data Engineers is skyrocketing. By 2026, millions of job opportunities are expected in this field, making it one of the most sought-after careers in tech.',
            ]
        }
    ];

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    // const imageList = [
    //     { Image: dev1, alt: "Award 1" },
    //     { Image: dev2, alt: "Award 2" },
    //     { Image: dev3, alt: "Award 3" },
    //     { Image: dev4, alt: "Award 4" },
    //     { Image: dev5, alt: "Award 5" },
    //     { Image: dev6, alt: "Award 6" },

    // ];

    const reviewList = [
        { Image: rev1, alt: "rev1" },
        { Image: rev2, alt: "rev1" },
        { Image: rev3, alt: "rev1" },
        { Image: rev4, alt: "rev1" },
        { Image: rev5, alt: "rev1" },
        { Image: rev6, alt: "rev1" },
        { Image: rev7, alt: "rev1" },
        { Image: rev8, alt: "rev1" },

    ]


    return (
        <div className="devops-page">
            <div className="content-container">
                <div className="devops-circle devops-circle-bottom-left"></div>
                <div className="left-content">
                    <h1>Sapalogy's- Best Data Engineering Training in Nagpur -Fees, Placements</h1>
                    <div className="stars-section">
                        {[...Array(4)].map((_, index) => (
                            <FontAwesomeIcon
                                key={index}
                                icon={faStar}
                                className="star-icon"
                            />
                        ))}
                    </div>
                    <div className="ratings-info">
                        <span className="rating">(4.4)</span>
                        <span className="separator">|</span>
                        <span className="ratings-count">4450 Ratings</span>
                        <span className="separator">|</span>
                        <span className="learners-count">5067 Learners</span>
                    </div>
                    <p>
                        Best Data Engineering Training by Sapalogy offers you the Best Data Engineering training in Nagpur for freshers and experienced candidates, with expert guidance and 100% guaranteed placement assistance. Our Data Engineering training course covers every technical topic in detail, ensuring students gain real-time hands-on experience.
                    </p>
                    <p>
                        Our practical, job-oriented training program not only provides you with a certification but also equips you with knowledge equivalent to 2+ years of industry experience. We value your time, which is why we have designed an intensive Data Engineering course that trains you in just 5 months.
                    </p>
                    <h3>All the topics will be covered in detail and also include:</h3>
                    <ul className="points-list">
                        <li><FontAwesomeIcon icon={faCheckCircle} /> GitHub & Version Control</li>
                        <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Data Warehousing (Snowflake, Redshift, BigQuery)</li>
                        <li><FontAwesomeIcon icon={faCheckCircle} /> ETL & ELT Pipelines (Airflow, dbt)</li>
                        <li><FontAwesomeIcon icon={faCheckCircle} /> Big Data Processing (Apache Spark, Hadoop)</li>
                        <li><FontAwesomeIcon icon={faCheckCircle} /> Cloud Platforms (AWS, GCP, Azure)</li>
                        <li><FontAwesomeIcon icon={faCheckCircle} /> SQL & NoSQL Databases (PostgreSQL, MongoDB)</li>
                        <li><FontAwesomeIcon icon={faFileAlt} /> Case Studies</li>
                        <li><FontAwesomeIcon icon={faChalkboardTeacher} /> 5+ Capstone Projects</li>
                        <li><FontAwesomeIcon icon={faBriefcase} /> Industry-level Projects</li>
                        <li><FontAwesomeIcon icon={faCheckCircle} /> 100% Job Opportunities Guaranteed Program</li>
                    </ul>


                </div>
                <PageForm />
            </div>
             <div className="video-section">
                <div className="video-container">
                    <iframe
                        src="https://www.youtube.com/embed/rYB3tA9-Sow?si=s0EU3Jc-VeZajYe-"
                        title="YouTube Video"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        className="responsive-iframe"
                    ></iframe>
                </div>
            </div> 
            <WhatsDevOps whatsData={whatsData} />
            <Roadmap heading="Roadmap to learn Data Engineering" roadmapData={roadmapData} />
            <Syllabus heading="Data Engineering Training syllabus" Syllabus={syllabus} />
            <KeyFeatures heading=" Data Engineering Training" keyFeatures={keyFeatures} />
            <BatchesPage Batches={BatchesPage} />
            <section className="devops-certification-section">
                <h2 className="certification-heading">Data Analytics Certification</h2>
                <div className="certification-content">
                    <div className="certification-points">
                        <ul>
                            <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
                            <li>The Data Engineering certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
                            <li>Sapalogy provides comprehensive guidance for your Data Engineering global certification, ensuring a 100% passing guarantee in examinations such as Data analytics Certification, Data Engineering Platform Development Certification, and various other global exams.</li>
                        </ul>
                    </div>
                    <div className="certification-image">
                        <img src={certificateImg} alt="DevOps Certification" />
                    </div>
                </div>
            </section>
            <JobPreparation />
            {/* <div className="slider-container">
                <h2 className="slider-heading">Data Analytics Project</h2>
                <RegularSlider sliderSettings={sliderSettings} images={imageList} />
            </div> */}
            <Marq />
            <Faq Faqs={faqs} />
            <div className="slider-container">
                <h2 className="slider-heading">Training courses review</h2>
                <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
            </div>
            <MarqueeComponent />
            <CourseOpportunities pageName="DataEngineering" />
            <Contact />
        </div>
    );
};

export default DataEngineering;