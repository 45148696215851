import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import '../Css/ContactForm.css';
import config from '../config';

function ContactForm() {
  const [isOpen, setIsOpen] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [formStatus, setFormStatus] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 7000);
    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setFormStatus("");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          access_key: "fabb3cfb-5cb8-4f83-81ae-b1c5caf0797a",
          subject: "New Contact Form Submission",
          from_name: "Sapalogy Training",
          name: data.name,
          phone: data.phone,
          course: data.course,
          recipient_email: "shivanihiware77@gmail.com",
        }),
      });

      const result = await response.json();
      if (result.success) {
        const dbResponse = await fetch(`${config.apiUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        const dbResult = await dbResponse.json();
        if (dbResult.success) {
          setFormStatus("Form submitted successfully!");
        } else {
          setFormStatus("Form submitted, but failed to save to the database.");
        }

        reset();
        handleClose();
      } else {
        setFormStatus("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      setFormStatus("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isOpen && (
        <div className="contact-form">
          <div className="contact-header">
            <h2>Contact Us</h2>
            <button onClick={handleClose} className="cancel-btn">X</button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label>Name:</label>
              <input
                type="text"
                placeholder="Your Name"
                {...register('name', { required: true })}
              />
              {errors.name && <p>Name is required</p>}
            </div>
            <div>
              <label>Phone No:</label>
              <input
                type="tel"
                placeholder="Your Phone Number"
                {...register('phone', { required: true, pattern: /^[0-9]{10}$/ })}
              />
              {errors.phone && <p>Valid phone number is required</p>}
            </div>
            <div>
              <label>Select Course:</label>
              <select
                {...register('course', { required: true })}
              >
                <option value="">-- Select a Course --</option>
                <option value="SAP">SAP</option>
                <option value="Salesforce">Salesforce</option>
                <option value="AWS">AWS</option>
                <option value="DevOps">DevOps</option>
                <option value="Python">Python</option>
                <option value="AI & ML">AI & ML</option>
                <option value="Data Analytics">Data Analytics</option>
                <option value="Business Analytics">Business Analytics</option>
                <option value="ServiceNow">ServiceNow</option>
                <option value="HR Training">HR Training</option>
                <option value="Share Market">Share Market</option>
                <option value="Data Engineering">Data Engineering</option>
              </select>
              {errors.course && <p>Course selection is required</p>}
            </div>
            <button className="submit" type="submit" disabled={loading}>
              {loading ? "Submitting..." : "Submit"}
            </button>
          </form>
          {formStatus && <p>{formStatus}</p>}
        </div>
      )}
    </>
  );
}

export default ContactForm;
