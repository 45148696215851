import React, { useState } from 'react';
import '../Css/DevOps.css';
import { useForm } from 'react-hook-form';
import config from '../config';

const PageForm = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [formStatus, setFormStatus] = useState(""); // For status messages
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        course: ""
    });
    const [loading, setLoading] = useState(false);

    const validateForm = () => {
        if (!formData.name || !formData.phone || !formData.course) {
            setFormStatus("All fields are required.");
            return false;
        }
        return true;
    };

    const onSubmit = async (data) => {
        setFormData(data); 

        if (!validateForm()) {
            return; 
        }

        setLoading(true);
        setFormStatus(""); 

        try {
            const web3Response = await fetch("https://api.web3forms.com/submit", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_key: "fabb3cfb-5cb8-4f83-81ae-b1c5caf0797a",
                    subject: "New Contact Form Submission",
                    from_name: "Sapalogy Training",
                    ...formData,
                    recipient_email: "shivanihiware77@gmail.com",
                }),
            });

            const web3Result = await web3Response.json();

            if (web3Result.success) {
                const dbResponse = await fetch(`${config.apiUrl}`, {  // Replace with your backend endpoint
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData),
                });

                const dbResult = await dbResponse.json();

                if (dbResult.success) {
                    setFormStatus("Form submitted successfully!");
                } else {
                    setFormStatus("Form submitted, but saving to the database failed.");
                }

                setFormData({ name: "", phone: "", course: "" });
                reset();
            } else {
                setFormStatus("Failed to submit the form. Please try again.");
            }
        } catch (error) {
            console.error("Error submitting the form:", error);
            setFormStatus("An error occurred. Please try again later.");
        } finally {
            setLoading(false); 
        }
    };

    return (
        <div className="devops-contact-form">
            <h2>Contact Us Now!</h2>
            <form className="contact-form-wrapper" onSubmit={handleSubmit(onSubmit)}>
                <label htmlFor="name">Name:</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Your Name"
                    value={formData.name}
                    {...register('name', {
                        required: "Name is required",
                        pattern: {
                            value: /^[a-zA-Z\s]{3,}$/,
                            message: "Name must be at least 3 characters and only contain letters",
                        },
                    })}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
                {errors.name && <p className="error">{errors.name.message}</p>}

                <label htmlFor="phone">Phone No:</label>
                <input
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="Your Phone No"
                    value={formData.phone}
                    {...register('phone', {
                        required: "Phone number is required",
                        pattern: {
                            value: /^\d{10}$/,
                            message: "Phone number must be exactly 10 digits",
                        },
                    })}
                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                />
                {errors.phone && <p className="error">{errors.phone.message}</p>}

                <label htmlFor="course">Select Your Course:</label>
                <select
                    id="course"
                    name="course"
                    value={formData.course}
                    {...register('course', { required: "Please select a course" })}
                    onChange={(e) => setFormData({ ...formData, course: e.target.value })}
                >
                    <option value="">-- Select a Course --</option>
                    <option value="salesforce">Salesforce</option>
                    <option value="SAP">SAP</option>
                    <option value="data analytics">Data Analytics</option>
                    <option value="business analytics">Business Analytics</option>
                    <option value="data science">Data Science</option>
                    <option value="devops">DevOps</option>
                    <option value="aws">AWS</option>
                    <option value="aiml">AI ML</option>
                    <option value="machine learning">Machine Learning</option>
                    <option value="full stack">Full Stack Development</option>
                    <option value="sharemarket">ServiceNow</option>
                    <option value="HR Training">HR Training</option>
                    <option value="servicenow">Sharemarket</option>
                    <option value="python">Python</option>
                    <option value="Tally">Tally</option>
                    <option value="Data Engineering">Data Engineering</option>
                </select>
                {errors.course && <p className="error">{errors.course.message}</p>}

                <button type="submit" className="submit-btn" disabled={loading}>
                    {loading ? "Submitting..." : "Submit"}
                </button>
            </form>

            {formStatus && <p className="form-status">{formStatus}</p>}
        </div>
    );
};

export default PageForm;
